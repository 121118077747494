import Container from 'react-bootstrap/Container';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Key.css";

const Key = () => {
    let navigate = useNavigate();
    const [passKey, setPassKey] = useState("");
    const [hide, setHide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const onAddNewItem = (e) => {
        e.preventDefault();
        navigate('/addmenuitem');
    };

    const retreiveKey = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/menu/cmpKey`, { key: passKey })
            .then(results => {
                setHide(results.data.output !== true);
                setIsLoading(false);
            })
            .catch(e => {
                setHide(true);
                setIsLoading(false);
            });
    };

    return (
      <div className="home-container">
        <Container>
          <br />
          <Row>
            <h3 className="animated-text">
              <span className="multicolor-text">WELCOME TO PRICE CENTER</span>
            </h3>
          </Row>

          <br />
          <Row className="menu-center-text mt-3">
            <Form className="mb-3" onSubmit={retreiveKey}>
              <Row>
                <Col className="col-md-2">
                  <Form.Control
                    value={passKey}
                    type="password"
                    onChange={(e) => setPassKey(e.target.value)}
                    id="key-name"
                    placeholder="Enter Key"
                  />
                </Col>
                <Col className="col-md-1">
                  <Button style={{ backgroundColor: 'darkred', borderColor: 'darkred' }} type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>

          {!hide && (
            <Row>
              <Col>
                <Button style={{ backgroundColor: 'darkred', borderColor: 'darkred' }} onClick={onAddNewItem}>
                  Add New Item
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
};

export default Key;
