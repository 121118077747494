import './App.css';
import Navigationbar from './navbar/Navbar';
import {BrowserRouter} from 'react-router-dom';
import React, { useRef } from 'react';
function App() {
  const videoRef = useRef(null);
  return (
    <BrowserRouter>
      <div className="App">
        <video
          className="background-video"
          src={"https://glassproducts.s3.us-east-1.amazonaws.com/video1.mp4"}
          autoPlay
          loop={true}
          muted={true}
          playsInline
        ></video>

        <Navigationbar />
      </div>
    </BrowserRouter>
  );
}

export default App;
