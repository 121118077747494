import Container from 'react-bootstrap/Container';
import { Row} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import "./Home.css";

const Home = () => {

    return (
      <div className="home-container">
        <Container>
          <br />
          <Row>
            <h3 className="animated-text">
              <span className="multicolor-text">WELCOME TO PRICE CENTER</span>
            </h3>
          </Row>

            <Row>
              <Carousel style={{ width: "100%" }}>
                {[
                  {
                    src: "https://glassproducts.s3.us-east-1.amazonaws.com/homeimage1.jpg",
                    alt: "Hand pipes",
                  },
                  {
                    src: "https://glassproducts.s3.us-east-1.amazonaws.com/homeimage2.jpg",
                    alt: "Bowls&downstems",
                  },
                  {
                    src: "https://glassproducts.s3.us-east-1.amazonaws.com/homeimage3.jpg",
                    alt: "Bubblers",
                  },
                  {
                    src: "https://glassproducts.s3.us-east-1.amazonaws.com/homeimage4.jpg",
                    alt: "Chillums",
                  },
                  {
                    src: "https://glassproducts.s3.us-east-1.amazonaws.com/homeimage5.jpg",
                    alt: "Smoking Mugs",
                  },
                  {
                    src: "https://glassproducts.s3.us-east-1.amazonaws.com/homeimage6.jpg",
                    alt: "Smoking Mugs",
                  },
                ].map((item, index) => (
                  <Carousel.Item key={index} interval={3000}>
                    <img
                      className="d-block w-100 slideimages"
                      src={item.src}
                      alt={item.alt}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Row>
        </Container>
      </div>
    );
};

export default Home;
