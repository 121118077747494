import React from 'react';
import Container from 'react-bootstrap/Container';
import {Row, Col, Pagination, Card, Form, Button, Badge, Modal, Alert} from 'react-bootstrap';
import Toggle from 'react-styled-toggle';
import './MugsMenu.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import axios from "axios";
import { useState, useEffect } from "react";
import Loader from '../loader/Loader';
import ErrorDisplayComp from '../common/errordisplaycomp/ErrorDisplayComp';

const MugsMenu = () => {
    const [activePage, setActivePage] = useState(0);
    const [error, setError] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    const [searchType, setSearchType] = useState('productId');
    const [pagesNum, setPagesNum] = useState(0);
    const [menuData, setMenuData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const [showUpdate, setShowUpdate] = useState(false);
    const [id, setId] = useState(0);
    const [errorMsg,setErrorMsg] = useState(false);
    const [passKey, setPassKey] = useState("");
    const [updateOutOfStock, setUpdateOutOfStock] = useState(0);
    const [successMessageUp,setSuccessMsgUp] = useState(false);
    const handleCloseUpdate = () => 
    {
      setShowUpdate(false);
    };
    const handleShowUpdate = () => setShowUpdate(true);
    //on page loads
    useEffect(
        () => {
            setMenuData(null);
            const getMenuItems = async () => {
                try {
                    let resp = await axios.post(`${process.env.REACT_APP_API_URL}/menu/getMugsMenuItemByName`, {
                        pageNum: activePage,
                        searchItem: searchItem,
                        searchType: searchType,
                        searchCategory: "Smoking Mugs"
                    });
                    setMenuData(resp.data);
                    
                    setError(false);
                }
                catch (err) {
                   // console.log(err.response.data.message);
                    setError(true);
                }
            }
            getPagesCount();
            getMenuItems();

        }, [activePage]);

        const updateItem = async(id, outOfStock) => {
            var formData = { 'outOfStock': parseInt(outOfStock), 'key': passKey};
      
            axios.post(`${process.env.REACT_APP_API_URL}/menu/updateitem/${id}`, formData, {
              headers: { "Content-Type": "application/json" }
            }).then(
              res => {
               if(res.data.message==='Unauthorized Access!')
                    setErrorMsg(true);
                else
                    setSuccessMsgUp(true);
         
              }
            ).catch(err => {
                setErrorMsg(true);
            })
        }

    const getPagesCount = async () => {
        try {
            let countRes = await axios.post(`${process.env.REACT_APP_API_URL}/menu/itemCount`,{searchCategory: "Smoking Mugs", searchType: searchType, searchItem: searchItem, searchSize: ""});
            setPagesNum(countRes.data.count);
        } catch (e) {
            setPagesNum(0);
        }
    }

    const searchItemByName = (itemName) => {
      
        setSearchItem(itemName);
    }

    const retreiveItems = (e) => {
        e.preventDefault();
        setIsLoading(true);
        getPagesCount();
        setActivePage(0);
        setMenuData([]);
        axios.post(`${process.env.REACT_APP_API_URL}/menu/getMugsMenuItemByName`, {
            pageNum: activePage,
            searchItem: searchItem,
            searchType: searchType,
            searchCategory: "Smoking Mugs"
        })
            .then(results => {
                setMenuData(results.data);
                setError(false);
                setIsLoading(false);
            }
            )
            .catch(e => {
                setError(true);
                setIsLoading(false);
                // setSearchItem("");
            })

    }

    const getThatPage = (number) => {
      //  console.log(parseInt(number.target.innerText));
        let pageNo = parseInt(number.target.innerText);
        setActivePage(pageNo);
    }
    let items = [];
    for (let number = 0; number <= pagesNum - 1; number++) {
        items.push(
            <Pagination.Item key={number} onClick={(e) => { getThatPage(e) }} active={number === activePage}>
                {number}
            </Pagination.Item>,
        );
    }

    return (<>
        <Container className='mt-2'>
        <Modal show={showUpdate} onHide={()=>handleCloseUpdate()}>
        <Modal.Header closeButton>
          <Modal.Title>Update stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
              <Row className="mb-4">
              <Form.Group as={Col} md="9" controlId="validationCustom03">
                <Toggle
                checked={updateOutOfStock}
                labelRight= "out of Stock" 
                labelLeft="In Stock"
                backgroundColorChecked="red"
                backgroundColorUnchecked="green"
                value={updateOutOfStock}
                onChange={(e) => { if(e.target.value==1)setUpdateOutOfStock(0); else setUpdateOutOfStock(1)}}
         
                />
                </Form.Group>
                
                </Row>
                <Row>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>KEY</Form.Label>
                  <Form.Control
                    required
                    type='password'
                    value={passKey}
                    onChange={(f) => { setPassKey(f.target.value) }}
                  />
                  <Form.Control.Feedback type="invalid">KEY is required</Form.Control.Feedback>
                </Form.Group>
                </Row>
                </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(f) => {handleCloseUpdate(f,10)}}>
            Close
          </Button>
          <Button variant="primary" onClick={async (f)=>{
            await handleCloseUpdate(f, 10);
            await updateItem(id, updateOutOfStock);
              }}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Col>
      {(successMessageUp===true)?  
      <Alert variant="success">
        <Alert.Heading>SUCCESS</Alert.Heading>
        <p>
        Item successfully Updated
        </p>
        <hr />
       
          <Button type='submit' variant="outline-success" onClick={(e)=>{setSuccessMsgUp(false);window.location.reload()}}>
            Close
          </Button>
       
      </Alert> 
      :null}
          {(errorMsg===true)?<Alert variant="danger">
        <Alert.Heading>FAILURE</Alert.Heading>
        <p>
        Failed to update item OR Unauthorized Access
        </p>
        <hr />
        
        <Button type='submit' variant="outline-danger" onClick={(e)=>{setErrorMsg(false);window.location.reload()}}>
            Close
          </Button>
      </Alert> :null}
      </Col>
            <Row className='menu-center-text'>
                <Col>
                <h2 style={{color: 'white'}}> SMOKING MUGS </h2>
                </Col>
            </Row>
            <Row className='menu-center-text mt-3'>
                    <Form className="mb-3" onSubmit={(e) => { retreiveItems(e) }} >
                        <Row>
                            <Col className='col-md-2' style={{ paddingRight: 0 }}>
                                <Form.Select 
                                value={searchType}
                                onChange={(e) => setSearchType(e.target.value)}
                                aria-label="Search Type"
                                style={{ backgroundColor: '#d3d3d3', borderRadius: '0' }}
                                >
                                <option value="productId">Product ID</option>
                                <option value="name">Name</option>
                                </Form.Select>
                            </Col>
                            <Col className='col-md-4' style={{ paddingLeft: 0 }}>
                            <Form.Control
                                value={searchItem}
                                onChange={(e) => { searchItemByName(e.target.value) }}
                                id="search-name"
                                placeholder={searchType=="productId"?"Search by ID":"Search by Name"}
                                aria-label="Search for item"
                                aria-describedby="basic-addon2"
                                style={{borderRadius: '0'}}
                            />
                            </Col>
                            
                            <Col className='col-md-2'>
                            <Button type='submit'  disabled={isLoading}>{isLoading ? 'Loading...' : 'Search'}</Button>
                            </Col>
                        </Row>
                    </Form>
            </Row>

        </Container>
        {(error === true) ? <Container>
            <Row>
                <Col>
                    <ErrorDisplayComp />
                </Col>
            </Row>
        </Container> : null
        }

        <Container className='mt-3'>
            <Row  >
                {
                    (menuData && menuData.length > 0) ?

                        menuData.map(eachItem => {
                            return (

                                <Col className='mb-3 card-group' xs={12} lg={3} md={6} key={eachItem.itemId}>
                                    <Card border={eachItem.outOfStock?'danger':'primary'} bg={eachItem.outOfStock?'danger':'light'}>
                                        <div className='bg-image hover-zoom'>
                                            {/* <Card.Img height={200} variant="top" src={eachItem.itemImage} /> */}
                                            <InnerImageZoom zoomScale='1.5' src={eachItem.itemImage} zoomSrc={eachItem.itemImage} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title>{eachItem.itemName}</Card.Title>
                                            <Card.Text style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                                Product ID: 
                                                <h3>
                                                    <Badge pill bg="primary" style={{ maxWidth: '100%', wordWrap: 'break-word', whiteSpace: 'normal', display: 'inline-block' }}>
                                                        {eachItem.itemProductId}
                                                    </Badge>
                                                </h3>
                                            </Card.Text>
                                            <Button variant={eachItem.outOfStock===0?'primary':'danger'} size='sm' onClick={ async (e)=>{
                                                    setUpdateOutOfStock(eachItem.outOfStock)
                                                    setId(eachItem._id)
                                                    handleShowUpdate()
                                                    }}><b>{eachItem.outOfStock===0?'In Stock':'Out of Stock'}</b></Button>
                                        </Card.Body>
                                        <Card.Footer className='remove-footer-prop'>
                                        </Card.Footer>

                                    </Card>
                                </Col>
                            )
                        })
                        :  (error !== true) ? <Container>
                            <Row>
                                <Col>
                                    <Loader />
                                </Col>
                            </Row>
                        </Container> : null
                        }
            </Row>
        </Container>
        <Container className='d-flex justify-content-center'>
            <Row>
                <Col>
                    <Pagination>{items}</Pagination>
                </Col>
            </Row>
        </Container>

    </>)
}

export default MugsMenu;